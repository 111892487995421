/* eslint-disable */

<template>
  <div class="vx-col">
    <vx-card class="mb-base">
      <div class="">
        <div
          :style="{ display: firewall_three === true ? '' : 'none!important' }"
          class="firewall-popup-block-2"
          style="background-color: #341f97"
        >
          <div class="eleventh-block">
            <h1 class="tenth-block-text">Firewall 3</h1>
            <img
              draggable="false"
              alt=""
              class="image-49 cursor-pointer"
              src="https://rapidsimulations.com/simulations/1/images/cancel.png"
              width="30"
              @click="firewall_three = false"
            />
          </div>
          <div class="tenth-line-firewall"></div>
          <div class="tenth-table-block">
            <div class="tenth-table-header-2">
              <div class="tenth-firewall-column w-sim-legacy-row">
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Rule Name</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Source</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Destination</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-2">
                  <p class="tenth-table-title">Service<br /></p>
                </div>
                <div class="w-sim-legacy-col w-sim-legacy-col-1">
                  <p class="tenth-table-title">Action<br /></p>
                </div>
              </div>
            </div>
            <div class="tenth-table-body">
              <div v-for="answer in firewall_one_answers" :key="answer.id" class="tenth-firewall-column-body w-sim-legacy-row">
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <h5>{{ answer.rule }}</h5>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'threeSource' + answer.id" class="ninth-select w-select">
                    <option v-for="item in source_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'threeDest' + answer.id" class="ninth-select w-select">
                    <option v-for="item in dest_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'threeService' + answer.id" class="ninth-select w-select">
                    <option v-for="item in service_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'threeAction' + answer.id" class="ninth-select w-select">
                    <option v-for="item in action_options" :key="item">{{ item }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :style="{ display: firewall_two === true ? '' : 'none!important' }"
          class="firewall-popup-block-2"
          style="background-color: #4169e1"
        >
          <div class="eleventh-block">
            <h1 class="tenth-block-text">Firewall 2</h1>
            <img
              draggable="false"
              alt=""
              class="image-49 cursor-pointer"
              src="https://rapidsimulations.com/simulations/1/images/cancel.png"
              width="30"
              @click="firewall_two = false"
            />
          </div>
          <div class="tenth-line-firewall"></div>
          <div class="tenth-table-block">
            <div class="tenth-table-header-2">
              <div class="tenth-firewall-column w-sim-legacy-row">
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Rule Name</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Source</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Destination</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-2">
                  <p class="tenth-table-title">Service<br /></p>
                </div>
                <div class="w-sim-legacy-col w-sim-legacy-col-1">
                  <p class="tenth-table-title">Action<br /></p>
                </div>
              </div>
            </div>
            <div class="tenth-table-body">
              <div v-for="answer in firewall_two_answers" :key="answer.id" class="tenth-firewall-column-body w-sim-legacy-row">
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <h5>{{ answer.rule }}</h5>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'twoSource' + answer.id" class="ninth-select w-select">
                    <option v-for="item in source_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'twoDest' + answer.id" class="ninth-select w-select">
                    <option v-for="item in dest_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'twoService' + answer.id" class="ninth-select w-select">
                    <option v-for="item in service_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'twoAction' + answer.id" class="ninth-select w-select">
                    <option v-for="item in action_options" :key="item">{{ item }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :style="{ display: firewall_one === true ? '' : 'none!important' }"
          class="firewall-popup-block-2"
          style="background-color: #10ac84"
        >
          <div class="eleventh-block">
            <h1 class="tenth-block-text">Firewall 1</h1>
            <img
              draggable="false"
              alt=""
              class="image-49 cursor-pointer"
              src="https://rapidsimulations.com/simulations/1/images/cancel.png"
              width="30"
              @click="firewall_one = false"
            />
          </div>
          <div class="tenth-line-firewall"></div>
          <div class="tenth-table-block">
            <div class="tenth-table-header-2">
              <div class="tenth-firewall-column w-sim-legacy-row">
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Rule Name</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Source</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <p class="tenth-table-title">Destination</p>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-2">
                  <p class="tenth-table-title">Service<br /></p>
                </div>
                <div class="w-sim-legacy-col w-sim-legacy-col-1">
                  <p class="tenth-table-title">Action<br /></p>
                </div>
              </div>
            </div>
            <div class="tenth-table-body">
              <div v-for="answer in firewall_one_answers" :key="answer.id" class="tenth-firewall-column-body w-sim-legacy-row">
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <h5>{{ answer.rule }}</h5>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'oneSource' + answer.id" class="ninth-select w-select">
                    <option v-for="item in source_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'oneDest' + answer.id" class="ninth-select w-select">
                    <option v-for="item in dest_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'oneService' + answer.id" class="ninth-select w-select">
                    <option v-for="item in service_options" :key="item">{{ item }}</option>
                  </select>
                </div>
                <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                  <select :id="'oneAction' + answer.id" class="ninth-select w-select">
                    <option v-for="item in action_options" :key="item">{{ item }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="simulation-10">
          <div class="columns-13 w-sim-legacy-row">
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3"></div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-public-network-2">
                <div class="tenth-public-network-bottom-2">
                  <div class="tenth-block">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://rapidsimulations.com/simulations/1/images/router.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">Datacenter<br />Router<br />10.0.0.254/24</h1>
                  </div>
                </div>
              </div>
              <div class="line-router-left"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-firewall-block-2 cursor-pointer" @click="show_firewall('firewall_one')" style="background-color: #10ac84">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://rapidsimulations.com/simulations/1/images/wall-2.png"
                    width="60"
                  />
                </div>
                <h1 class="tenth-internet-text">Firewall 1</h1>
              </div>
              <div class="firewall-2-line"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-server">
                <div class="tenth-public-network-bottom-2">
                  <div class="tenth-block">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://rapidsimulations.com/simulations/1/images/database.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">Web Server<br />10.0.0.1/24</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns-13 w-sim-legacy-row">
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-internet-block-2">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://rapidsimulations.com/simulations/1/images/global.png"
                    width="60"
                  />
                </div>
                <h1 class="tenth-internet-text">Internet</h1>
              </div>
              <div class="line-internet-up"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="datacentre-to-wall-2"></div>
              <div class="line-internet-up"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-firewall-block-2 cursor-pointer" @click="show_firewall('firewall_two')" style="background-color: #4169e1">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://rapidsimulations.com/simulations/1/images/wall-2.png"
                    width="60"
                  />
                </div>
                <h1 class="tenth-internet-text">Firewall 2</h1>
              </div>
              <div class="firewall-2-line"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-server">
                <div class="tenth-public-network-bottom-2">
                  <div class="tenth-block">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://rapidsimulations.com/simulations/1/images/database.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">Email Server<br />10.0.1.1/24</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns-13 w-sim-legacy-row">
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3"></div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="line-router-left"></div>
              <div class="tenth-public-network-bottom-2">
                <div class="tenth-public-network-2">
                  <div class="tenth-block">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://rapidsimulations.com/simulations/1/images/router.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">DR <br />Router<br />192.168.0.254/24</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-firewall-block-2 cursor-pointer" @click="show_firewall('firewall_three')" style="background-color: #341f97">
                <div class="tenth-image-block">
                  <img
                    draggable="false"
                    alt=""
                    class="tenth-image"
                    src="https://rapidsimulations.com/simulations/1/images/wall-2.png"
                    width="60"
                  />
                </div>
                <h1 class="tenth-internet-text">Firewall 3</h1>
              </div>
              <div class="firewall-2-line"></div>
            </div>
            <div class="center-2 w-sim-legacy-col w-sim-legacy-col-3">
              <div class="tenth-server">
                <div class="tenth-public-network-bottom-2">
                  <div class="tenth-block">
                    <div class="tenth-image-block">
                      <img
                        draggable="false"
                        alt=""
                        class="tenth-image"
                        src="https://rapidsimulations.com/simulations/1/images/database.png"
                        width="50"
                      />
                    </div>
                    <h1 class="tenth-block-text-small">Web Server<br />192.168.0.1/24</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      firewall_one: false,
      firewall_two: false,
      firewall_three: false,

      current_page: 0,

      source_options: ['ANY', '10.0.0.1/24', '10.0.1.1/24', '192.168.0.1/24'],

      dest_options: ['ANY', '10.0.0.1/24', '10.0.1.1/24', '192.168.0.1/24'],

      service_options: ['ANY', 'DNS', 'HTTP', 'HTTPS', 'TELNET', 'SSH'],

      action_options: ['PERMIT', 'DENY'],

      firewall_one_answers: [
        { id: '1', rule: 'DNS Rule', source: 'ANY', dest: 'ANY', service: 'DNS', action: 'PERMIT' },
        {
          id: '2',
          rule: 'HTTPS Outbound',
          source: '10.0.0.1/24',
          dest: 'ANY',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        { id: '3', rule: 'Management', source: 'ANY', dest: '10.0.0.1/24', service: 'SSH', action: 'PERMIT' },
        {
          id: '4',
          rule: 'HTTPS Inbound',
          source: 'ANY',
          dest: '10.0.0.1/24',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        {
          id: '5',
          rule: 'HTTP Inbound',
          source: 'ANY',
          dest: '10.0.0.1/24',
          service: 'HTTP',
          action: 'DENY',
        },
      ],

      firewall_two_answers: [
        { id: '1', rule: 'DNS Rule', source: 'ANY', dest: 'ANY', service: 'DNS', action: 'PERMIT' },
        {
          id: '2',
          rule: 'HTTPS Outbound',
          source: '10.0.1.1/24',
          dest: 'ANY',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        { id: '3', rule: 'Management', source: 'ANY', dest: '10.0.1.1/24', service: 'SSH', action: 'PERMIT' },
        {
          id: '4',
          rule: 'HTTPS Inbound',
          source: 'ANY',
          dest: '10.0.1.1/24',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        { id: '5', rule: 'HTTP Inbound', source: 'ANY', dest: '10.0.1.1/24', service: 'HTTP', action: 'DENY' },
      ],

      firewall_three_answers: [
        { id: '1', rule: 'DNS Rule', source: 'ANY', dest: 'ANY', service: 'DNS', action: 'PERMIT' },
        {
          id: '2',
          rule: 'HTTPS Outbound',
          source: '192.168.0.1/24',
          dest: 'ANY',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        {
          id: '3',
          rule: 'Management',
          source: 'ANY',
          dest: '192.168.0.1/24',
          service: 'SSH',
          action: 'PERMIT',
        },
        {
          id: '4',
          rule: 'HTTPS Inbound',
          source: 'ANY',
          dest: '192.168.0.1/24',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        {
          id: '5',
          rule: 'HTTP Inbound',
          source: 'ANY',
          dest: '192.168.0.1/24',
          service: 'HTTP',
          action: 'DENY',
        },
      ],
    };
  },
  methods: {
    show_firewall(x) {
      if (x === 'firewall_one') {
        this.firewall_one = true;
        this.firewall_two = false;
        this.firewall_three = false;
      }
      if (x === 'firewall_two') {
        this.firewall_one = false;
        this.firewall_two = true;
        this.firewall_three = false;
      }
      if (x === 'firewall_three') {
        this.firewall_one = false;
        this.firewall_two = false;
        this.firewall_three = true;
      }
    },
    markSimulation() {
      let totalScore = 0;

      this.firewall_one_answers.forEach((x) => {
        if (x.source === document.getElementById(`oneSource${x.id}`).value) {
          document.getElementById(`oneSource${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`oneSource${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`oneSource${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`oneSource${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.dest === document.getElementById(`oneDest${x.id}`).value) {
          document.getElementById(`oneDest${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`oneDest${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`oneDest${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`oneDest${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.service === document.getElementById(`oneService${x.id}`).value) {
          document.getElementById(`oneService${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`oneService${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`oneService${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`oneService${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.action === document.getElementById(`oneAction${x.id}`).value) {
          document.getElementById(`oneAction${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`oneAction${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`oneAction${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`oneAction${x.id}`).style.backgroundColor = '#fd4445';
        }
      });

      this.firewall_two_answers.forEach((x) => {
        if (x.source === document.getElementById(`twoSource${x.id}`).value) {
          document.getElementById(`twoSource${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`twoSource${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`twoSource${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`twoSource${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.dest === document.getElementById(`twoDest${x.id}`).value) {
          document.getElementById(`twoDest${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`twoDest${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`twoDest${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`twoDest${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.service === document.getElementById(`twoService${x.id}`).value) {
          document.getElementById(`twoService${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`twoService${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`twoService${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`twoService${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.action === document.getElementById(`twoAction${x.id}`).value) {
          document.getElementById(`twoAction${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`twoAction${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`twoAction${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`twoAction${x.id}`).style.backgroundColor = '#fd4445';
        }
      });
      this.firewall_three_answers.forEach((x) => {
        if (x.source === document.getElementById(`threeSource${x.id}`).value) {
          document.getElementById(`threeSource${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`threeSource${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`threeSource${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`threeSource${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.dest === document.getElementById(`threeDest${x.id}`).value) {
          document.getElementById(`threeDest${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`threeDest${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`threeDest${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`threeDest${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.service === document.getElementById(`threeService${x.id}`).value) {
          document.getElementById(`threeService${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`threeService${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`threeService${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`threeService${x.id}`).style.backgroundColor = '#fd4445';
        }
        if (x.action === document.getElementById(`threeAction${x.id}`).value) {
          document.getElementById(`threeAction${x.id}`).style.borderColor = '#77FAAE';
          document.getElementById(`threeAction${x.id}`).style.backgroundColor = '#77FAAE';
          totalScore++;
        } else {
          document.getElementById(`threeAction${x.id}`).style.borderColor = '#fd4445';
          document.getElementById(`threeAction${x.id}`).style.backgroundColor = '#fd4445';
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.w-select {
  font-weight: 800;
}

@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx179903403193794560.scss';
</style>
